import { Edit } from "react-admin";
import { AlbumFormInputs } from "./AlbumFormInputs";

export const AlbumEdit = () => {
  return (
    <Edit>
      <AlbumFormInputs isEdit />
    </Edit>
  );
};
