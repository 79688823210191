import { Fragment } from "react";
import { TextInput } from "react-admin";
import { DEFAULT_TAG_FIELDS } from "../../constants";

export const TagTypeFormInputs = () => (
  <Fragment>
    <TextInput
      source="name"
    />
  </Fragment>
);
