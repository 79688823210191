import { Edit } from "react-admin";
import { TrackFormInputs } from "./TrackFormInputs";

export const TrackEdit = () => {
  return (
    <Edit>
      <TrackFormInputs isEdit />
    </Edit>
  );
};
