import { Backdrop, CircularProgress } from "@mui/material";
import { FC, PropsWithChildren } from "react";

type TLoadable = {
  isLoading: boolean;
} & PropsWithChildren;

export const Loadable: FC<TLoadable> = ({ children, isLoading }) => {
  return (
    <>
      {children}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
