import { addRefreshAuthToAuthProvider, AuthProvider } from "react-admin";
import { axiosInstance } from "../index";
import { LoginResponse } from "../../types";
import { SessionService } from "../../utils";

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const login = await axiosInstance.post<LoginResponse>("auth/admin/login", { email: username, password });

    SessionService.set(login.data);
    return Promise.resolve();
  },
  logout: async () => {
    try {
      const session = SessionService.get();
      await axiosInstance.post("auth/admin/logout", {
        accessToken: session.accessToken,
        refreshToken: session.refreshToken,
      });
    } catch (e) {
      console.error(e);
    } finally {
      SessionService.clear();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    const session = SessionService.get();

    if (!session.accessToken) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error?.response?.status;

    if (status === 401) {
      SessionService.removeAccessToken();
      return SessionService.refresh();
    }

    if (status === 403) {
      SessionService.clear();
      return Promise.reject();
    }

    return Promise.resolve();
  },
  getIdentity: () => SessionService.getMe(),
  getPermissions: () => Promise.resolve({}),
};

export const authProviderWithRefresh = addRefreshAuthToAuthProvider(authProvider, SessionService.refresh);
