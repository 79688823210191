import { axiosInstance } from "../middleware";
import { TAlbum } from "../types/data";
import { TDrafts, TUrl } from "./types";

class BulkUpload {
  constructor(
    private readonly apiUrl = "/albums/bulk-upload",
    private readonly apiUrlFiles = "/albums/bulk-upload/files",
  ) {}

  async downloadTemplate() {
    const { data } = await axiosInstance.get(this.apiUrl + `/export`);
    const blob = new Blob([data], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "template.csv";
    link.click();
  }

  async getDrafts() {
    const { data } = await axiosInstance.get<TAlbum[]>(this.apiUrl);
    return data;
  }

  async uploadCsv(rawFile: File) {
    const formData = new FormData();
    formData.append("data", rawFile);

    return await axiosInstance<TDrafts>(this.apiUrl, {
      method: "POST",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  async getTrackUrl(fileName: string) {
    return axiosInstance.get<TUrl>(this.apiUrlFiles + `/tracks/${fileName}`);
  }

  async getCoverUrl(fileName: string) {
    return axiosInstance.get<TUrl>(this.apiUrlFiles + `/albums/${fileName}`);
  }

  async approveDrafts() {
    return axiosInstance.patch<TDrafts>(this.apiUrl);
  }

  async declineDrafts() {
    return axiosInstance.delete(this.apiUrl);
  }
}

export const bulkUploadService = new BulkUpload();
