import { FunctionField, UrlField } from "react-admin";

interface FieldProps {
  source: string;
}

export const DealUrlField = (props: FieldProps) => {
  const source = props.source;

  return (
    <FunctionField
      source={source}
      render={(record) => {
        if (!record || !record[source]) {
          return null;
        }

        return (
          <UrlField
            target="_blank"
            rel="noopener noreferrer"
            source={props.source}
            record={record}
            href={record[source]}
          />
        );
      }}
    />
  );
};
