import { Show, SimpleShowLayout, TextField } from "react-admin";

export const DealTagsShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  );
};
