import { Create, SimpleForm } from "react-admin";
import { TagFormInputs } from "./TagFormInputs";

export const TagCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TagFormInputs />
      </SimpleForm>
    </Create>
  );
};
