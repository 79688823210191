import { useMemo } from "react";
import { required, SelectInput, SimpleForm, useEditContext, useCreateContext, useRecordContext } from "react-admin";
import { TracksInput } from "../../components";
import { PAGE_TYPE_SELECT_OPTIONS } from "../../constants";

interface SoundExampleFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

export const SoundExampleFormInputs = (props: SoundExampleFormInputsProps) => {
  const record = useRecordContext();
  const { isCreate, isEdit } = props;
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();

  const onSubmit = (data) => {
    if (isEdit) {
      saveOnEdit(data);
      return;
    }

    if (isCreate) {
      saveOnCreate(data);
      return;
    }
  };

  const transformedRecord = useMemo(() => {
    if (!record) {
      return undefined;
    }

    const { tracks, ...restData } = record;

    return {
      tracks: record?.tracks?.map((el) => el._id),
      ...restData,
    };
  }, [record]);

  return (
    <SimpleForm onSubmit={onSubmit} record={transformedRecord}>
      <SelectInput choices={PAGE_TYPE_SELECT_OPTIONS} optionValue="name" source="page" validate={required()} />
      <TracksInput required source="tracks" entityName="sound example" />
    </SimpleForm>
  );
};
