import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, useRecordContext, useNotify } from "react-admin";
import { downloadsService } from "../../../services";

export const ResetDownloadsButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleReset = () => {
    if (!record) return;
    downloadsService
      .reset(String(record.id))
      .then(() => {
        notify(`Downloads are reset`, { type: "success" });
      })
      .catch(() => {
        notify(`Error`, { type: "error" });
      });
  };

  return <Button startIcon={<RefreshIcon />} label="Reset Downloads" onClick={handleReset} />;
};
