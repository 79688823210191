import { Create } from "react-admin";
import { TrackFormInputs } from "./TrackFormInputs";

export const TrackCreate = () => {
  return (
    <Create>
      <TrackFormInputs isCreate />
    </Create>
  );
};
