import { Create, SimpleForm } from "react-admin";
import { UsersFormInputs } from "./UsersFormInputs";

export const UsersCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <UsersFormInputs isCreate/>
      </SimpleForm>
    </Create>
  );
};
