import { Datagrid, EditButton, List, RichTextField, ShowButton, TextField } from "react-admin";

export const TermsList = () => {
  return (
    <List perPage={25}>
      <Datagrid>
        <TextField source="type" />
        <RichTextField source="text" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
