import { useMemo } from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useShowContext,
} from "react-admin";

const TagsList = () => {
  const { isLoading, record } = useShowContext();

  if (isLoading) {
    return null;
  }

  const modifiedTags = useMemo(() => {
    if (!record?.tags?.length || !record.tags[0]._id) {
      return [];
    }

    return (record.tags || []).reduce((res, el) => {
      const existingGroup = res.find((group) => group.tagType === el.tagTypeId.name);

      if (existingGroup) {
        existingGroup.tags.push({ tag: el.value });
      } else {
        res.push({
          tags: [{ tag: el.value }],
          tagType: el.tagTypeId.name,
        });
      }

      return res;
    }, []);
  }, [record.tags]);

  return (
    <ArrayField source="tags" record={{ tags: modifiedTags }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="tagType" sortable={false} />
        <ArrayField source="tags" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="tag" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </ArrayField>
  );
};

export const TrackShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
        <ReferenceField source="composerId" reference="composers" />
        <NumberField source="length" />
        <NumberField source="bpm" />
        <ReferenceField reference="albums" source="albumId" />
        <TagsList />
      </SimpleShowLayout>
    </Show>
  );
};
