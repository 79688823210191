import { Edit, SimpleForm } from "react-admin";
import { ComposersFormInputs } from "./ComposersFormInputs";

export const ComposersEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <ComposersFormInputs />
      </SimpleForm>
    </Edit>
  );
};
