import { Edit, SimpleForm } from "react-admin";
import TermsFormInputs from "./TermsFormInputs";

export const TermsEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TermsFormInputs />
      </SimpleForm>
    </Edit>
  );
};
