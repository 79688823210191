import { Show } from "react-admin";
import { DealsShowFields } from "./DealsShowFields";

export const DealsShow = () => {
  return (
    <Show>
      <DealsShowFields />
    </Show>
  );
};
