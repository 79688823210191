import { Create } from "react-admin";
import { DealsFormInputs } from "./DealsFormInputs";

export const DealCreate = () => {
  return (
    <Create>
      <DealsFormInputs isCreate />
    </Create>
  );
};
