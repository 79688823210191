import { Edit, SimpleForm } from "react-admin";
import { UsersFormInputs } from "./UsersFormInputs";

export const UsersEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <UsersFormInputs isEdit />
      </SimpleForm>
    </Edit>
  );
};
