import { addRefreshAuthToDataProvider, CreateParams, DataProvider, UpdateParams } from "react-admin";
import { SessionService } from "../../../utils";
import { axiosInstance } from "../../axios";
import { baseDataProvider } from "./base-data-provider";

enum SpecificResources {
  Albums = "albums",
  Tracks = "tracks",
  Deals = "deals",
  SliderDeals = "slider-deals",
}

const updateAlbumCover = async (resource: string, params: CreateParams | UpdateParams, albumId: string) => {
  const formData = new FormData();
  formData.append("cover", params.data.cover.rawFile);

  return await axiosInstance(`${resource}/${albumId}/cover`, {
    method: "PATCH",
    data: formData,
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then(({ data }) => ({
    data: { ...data, id: data._id },
  }));
};

const updateTrackFiles = async (resource: string, params: CreateParams | UpdateParams, trackId: string) => {
  const formData = new FormData();
  const {
    data: { file, lowQualityFile, streamingFile },
  } = params;

  if (file) {
    formData.append("file", file.rawFile);
  }

  if (lowQualityFile) {
    formData.append("lowQualityFile", lowQualityFile.rawFile);
  }

  if (streamingFile) {
    formData.append("streamingFile", streamingFile.rawFile);
  }

  return await axiosInstance(`${resource}/${trackId}/files`, {
    method: "PATCH",
    data: formData,
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then(({ data }) => ({
    data: { ...data, id: data._id },
  }));
};

const updateDealImage = async (resource: string, params: CreateParams | UpdateParams, dealId: string) => {
  const formData = new FormData();
  formData.append("image", params.data.image.rawFile);

  return await axiosInstance(`${resource}/${dealId}/image`, {
    method: "PATCH",
    data: formData,
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then(({ data }) => ({
    data: { ...data, id: data._id },
  }));
};

const createDeal = async (resource: string, params: CreateParams) => {
  const createdInstance = await baseDataProvider.create(resource, params);

  if (params.data.image) {
    return await updateDealImage(resource, params, createdInstance.data.id);
  }

  return createdInstance;
};

const updateDeal = async (resource: string, params: UpdateParams) => {
  const updatedInstance = await baseDataProvider.update(resource, params);

  if (params.data.image) {
    return await updateDealImage(resource, params, updatedInstance.data.id);
  }

  return updatedInstance;
};

const extendedCreate: DataProvider["create"] = async (resource, params) => {
  switch (resource) {
    case SpecificResources.Albums: {
      const createdInstance = await baseDataProvider.create(resource, params);
      if (params.data.cover) {
        return await updateAlbumCover(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Tracks: {
      const createdInstance = await baseDataProvider.create(resource, params);

      if (params.data.file || params.data.lowQualityFile || params.data.streamingFile) {
        return await updateTrackFiles(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Deals: {
      return createDeal(resource, params);
    }
    case SpecificResources.SliderDeals: {
      return createDeal(resource, params);
    }
    default:
      return await baseDataProvider.create(resource, params);
  }
};

const extendedUpdate: DataProvider["update"] = async (resource, params) => {
  switch (resource) {
    case SpecificResources.Albums: {
      const createdInstance = await baseDataProvider.update(resource, params);
      if (params.data.cover) {
        return await updateAlbumCover(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Tracks: {
      const createdInstance = await baseDataProvider.update(resource, params);

      if (params.data.file || params.data.lowQualityFile || params.data.streamingFile) {
        return await updateTrackFiles(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Deals: {
      return updateDeal(resource, params);
    }
    case SpecificResources.SliderDeals: {
      return updateDeal(resource, params);
    }
    default:
      return await baseDataProvider.update(resource, params);
  }
};

const extendedDataProvider: DataProvider = {
  ...baseDataProvider,
  create: extendedCreate,
  update: extendedUpdate,
};

export const dataProviderWithRefresh = addRefreshAuthToDataProvider(extendedDataProvider, SessionService.refresh);
