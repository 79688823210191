import { Button, Paper, Stack } from "@mui/material";
import { FC, Fragment, useCallback } from "react";
import { FileField, FileInput, ImageField, ImageInput, Link, useCreatePath } from "react-admin";
import { useFormContext } from "react-hook-form";
import uniqueId from "lodash/uniqueId";
import { bulkUploadService } from "../../services";
import { TDrafts, TUploadFileValidationError } from "../../services/types";
import { FileType } from "./constants";
import { isDraftsWithValidationErrors, uploadFiles } from "./utils";

type THandleFilesButtons = {
  onApply: () => void;
  onReset: () => void;
  isDisabled: boolean;
};

type TBulkUploadFormInputs = {
  drafts: TDrafts | null;
  setIsLoading: (isLoading: boolean) => void;
  setDrafts: (drafts: TDrafts) => void;
};

const HandleFilesButtons: FC<THandleFilesButtons> = ({ onApply, onReset, isDisabled }) => {
  return (
    <Stack direction="row" justifyContent="end" spacing="32px" mb="1em" width="100%">
      <Button disabled={isDisabled} variant="contained" onClick={onApply}>
        Upload Files
      </Button>
      <Button disabled={isDisabled} color="error" onClick={onReset}>
        Clear
      </Button>
    </Stack>
  );
};

export const BulkUploadFormInputs: FC<TBulkUploadFormInputs> = ({ setIsLoading, drafts, setDrafts }) => {
  const { watch, resetField, getValues } = useFormContext();
  const createPath = useCreatePath();

  const handleReset = (fileType: FileType) => {
    resetField(fileType);
  };

  const handleUploadFiles = useCallback(
    async (fileType: FileType) => {
      try {
        setIsLoading(true);
        const isTracks = fileType === FileType.TRACKS;
        await uploadFiles({ isTracks, files: getValues(fileType)?.map(({ rawFile }) => rawFile) });
        resetField(fileType);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
    [watch(FileType.TRACKS), watch(FileType.COVERS)],
  );

  const handleUploadCsv = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await bulkUploadService.uploadCsv(getValues(FileType.CSV).rawFile);
      setDrafts(data);
      resetField(FileType.CSV);
    } catch (err) {
      const { response } = err as TUploadFileValidationError;
      setDrafts(response.data.details.validationErrors);
    } finally {
      setIsLoading(false);
    }
  }, [watch(FileType.CSV)]);

  return (
    <>
      <FileInput accept=".csv" source={FileType.CSV} label="CSV">
        <FileField source="src" title="title" />
      </FileInput>
      <HandleFilesButtons
        isDisabled={!watch(FileType.CSV)}
        onReset={() => handleReset(FileType.CSV)}
        onApply={handleUploadCsv}
      />
      <FileInput multiple accept=".wav,.mp3,.ogg" source={FileType.TRACKS} label="Tracks">
        <FileField source="src" title="title" />
      </FileInput>
      <HandleFilesButtons
        isDisabled={!watch(FileType.TRACKS)}
        onReset={() => handleReset(FileType.TRACKS)}
        onApply={() => handleUploadFiles(FileType.TRACKS)}
      />
      <ImageInput multiple source={FileType.COVERS} label="Album Covers">
        <ImageField source="src" title="title" />
      </ImageInput>
      <HandleFilesButtons
        isDisabled={!watch(FileType.COVERS)}
        onReset={() => handleReset(FileType.COVERS)}
        onApply={() => handleUploadFiles(FileType.COVERS)}
      />
      {drafts && (
        <Paper variant="outlined" sx={{ width: "100%", mb: "24px", p: "16px" }}>
          <ul>
            {isDraftsWithValidationErrors(drafts)
              ? drafts.map(({ constraints, property }) => (
                  <Fragment key={uniqueId("property_")}>
                    <li>{property}</li>
                    {Object.values(constraints) && (
                      <ul>
                        {Object.values(constraints).map((constraint) => (
                          <li key={uniqueId("constraint_")}>{constraint}</li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                ))
              : drafts.map(({ tracks, name, _id }) => (
                  <Fragment key={_id}>
                    <li>
                      <Link to={createPath({ resource: "albums", type: "show", id: _id })}>{name}</Link>
                    </li>
                    {tracks && (
                      <ul>
                        {tracks.map((track) => (
                          <li key={track._id}>
                            <Link to={createPath({ resource: "tracks", type: "show", id: track._id })}>
                              {track.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                ))}
          </ul>
        </Paper>
      )}
    </>
  );
};
