import { Button, Paper, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SimpleForm, useNotify } from "react-admin";
import { Loadable } from "../../components";
import { bulkUploadService } from "../../services";
import { TDrafts } from "../../services/types";
import { BulkUploadFormInputs } from "./BulkUploadFormInputs";
import { DEFAULT_VALUES } from "./constants";
import { isDraftsWithValidationErrors } from "./utils";

type TActionButtons = {
  isDisabled: boolean;
  clearDrafts: () => void;
  setIsLoading: (isLoading: boolean) => void;
};

const ActionButtons: FC<TActionButtons> = ({ isDisabled, clearDrafts, setIsLoading }) => {
  const notify = useNotify();
  const handleNotify = (message: string) => {
    notify(message, { type: "error" });
  };
  return (
    <Stack
      minHeight="64px"
      direction="row"
      px="16px"
      bgcolor="#f5f5f5"
      justifyContent="space-between"
      alignItems="center"
    >
      <Button
        disabled={isDisabled}
        variant="contained"
        onClick={async () => {
          try {
            setIsLoading(true);
            await bulkUploadService.approveDrafts();
            clearDrafts();
          } catch (err: any) {
            handleNotify(err?.response.data.message);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        Publish Drafts
      </Button>
      <Button
        disabled={isDisabled}
        color="error"
        onClick={async () => {
          try {
            setIsLoading(true);
            await bulkUploadService.declineDrafts();
            clearDrafts();
          } catch (err: any) {
            handleNotify(err?.response.data.message);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        Discard Drafts
      </Button>
    </Stack>
  );
};

export const BulkUpload = () => {
  const [drafts, setDrafts] = useState<TDrafts | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDrafts = async () => {
      try {
        const fetchedDrafts = await bulkUploadService.getDrafts();
        setDrafts(fetchedDrafts);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDrafts();
  }, []);

  return (
    <Loadable isLoading={isLoading}>
      <Stack mt="1em">
        <Paper sx={{ overflow: "hidden" }}>
          <SimpleForm
            toolbar={
              <ActionButtons
                isDisabled={!drafts || isDraftsWithValidationErrors(drafts)}
                clearDrafts={() => setDrafts(null)}
                setIsLoading={setIsLoading}
              />
            }
            defaultValues={DEFAULT_VALUES}
          >
            <BulkUploadFormInputs drafts={drafts} setIsLoading={setIsLoading} setDrafts={setDrafts} />
          </SimpleForm>
        </Paper>
      </Stack>
    </Loadable>
  );
};
