import { Create } from "react-admin";
import { DealTagsFormInputs } from "./DealTagsFormInputs";

export const DealTagsCreate = () => {
  return (
    <Create>
      <DealTagsFormInputs isCreate />
    </Create>
  );
};
