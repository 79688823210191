import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SimpleFormIterator,
} from "react-admin";
import { REFERENCE_ARRAY_INPUT_SX } from "../constants";

interface TracksInputProps {
  filter?: unknown;
  source: string;
  entityName: string;
  required?: boolean;
}

export const TracksInput = (props: TracksInputProps) => {
  const { filter, entityName, required: isRequired } = props;

  return (
    <ArrayInput source={props.source} validate={isRequired && required()}>
      <SimpleFormIterator getItemLabel={(index) => `${index + 1}`}>
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            const source = getSource("");

            return (
              <ReferenceInput
                source={source.substring(0, source.length - 1)}
                reference="tracks"
                filter={filter}
                {...rest}
              >
                <AutocompleteInput
                  source="id"
                  label="Track"
                  sx={REFERENCE_ARRAY_INPUT_SX}
                  filterToQuery={(q) => ({ name: q })}
                  validate={[
                    required(),
                    (value, values) => {
                      const isInvalid = values.tracks.filter((el) => el === value).length > 1;
                      if (!isInvalid) {
                        return null;
                      }

                      return `Track is already presented in ${entityName.toLowerCase()}`;
                    },
                  ]}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
