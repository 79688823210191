import { Fragment, FunctionComponent } from "react";
import { BooleanInput, TextInput } from "react-admin";

interface UserFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

export const UsersFormInputs: FunctionComponent<UserFormInputsProps> = ({ isCreate, isEdit }) => (
  <Fragment>
    <TextInput
      source="firstName"
    />
    <TextInput
      source="email"
    />
    <TextInput
      source="description"
    />
    {isCreate && (
      <>
        <TextInput
          source="password"
        />
        <BooleanInput
          source="hasAcceptedTerms"
        />
        <BooleanInput
          source="hasNewsletter"
        />
        <BooleanInput
          source="hasGeneralNewsletter"
        />
      </>
    )}
    <TextInput
      source="socialLinks.youtube"
    />
    <TextInput
      source="socialLinks.instagram"
    />
    <TextInput
      source="socialLinks.facebook"
    />
    <TextInput
      source="socialLinks.tiktok"
    />
    <TextInput
      source="socialLinks.business"
    />
  </Fragment>
);
