import { stringify } from "qs";
import { DataProvider, HttpError } from "react-admin";
import { axiosInstance } from "../../axios";

export const baseDataProvider: DataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: {
        [field]: order,
      },
      page: page,
      limit: perPage,
      filter: params.filter,
    };
    const url = `${resource}?${stringify(query)}`;

    return axiosInstance(url)
      .then((res) => {
        const { headers, data } = res;
        return ({
          data: data.map((el) => ({ ...el, id: el._id })),
          total: parseInt(headers["content-range"].split("/").pop(), 10),
        });
      })
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  getOne: (resource, params) => (
    axiosInstance(`${resource}/${params.id}`)
      .then(({ data }) => ({
        data: { ...data, id: data._id },
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      })
  ),

  getMany: (resource, params) => {
    const query = {
      filter: { ids: params.ids },
    };

    const url = `${resource}?${stringify(query, { arrayFormat: 'brackets'})}`;

    return axiosInstance(url)
      .then(({ data }) => ({
        data: data.map((el) => ({ ...el, id: el._id })),
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: {
        [field]: order,
      },
      page: page,
      limit: perPage,
      filter: {
        ...params.filter,
        [params.target]: params.id,
      },
    };
    const url = `${resource}?${stringify(query)}`;

    return axiosInstance(url)
      .then(({ headers, data }) => ({
        data: data.map((el) => ({ ...el, id: el._id })),
        total: parseInt(headers["content-range"].split("/").pop(), 10),
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  create: (resource, params) => (
    axiosInstance(`${resource}`, {
      method: "POST",
      data: params.data,
    })
      .then(({ data }) => ({
        data: { ...params.data, ...data, id: data._id },
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      })
  ),

  update: (resource, params) => (
    axiosInstance(`${resource}/${params.id}`, {
      method: "PATCH",
      data: params.data,
    })
      .then(({ data }) => ({
        data: { ...data, id: data._id },
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      })
  ),

  updateMany: (resource, params) => {
    const query = {
      filter: { id: params.ids },
    };
    return axiosInstance(`${resource}?${stringify(query)}`, {
      method: "PATCH",
      data: params.data,
    })
      .then(({ data }) => ({ data }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  delete: (resource, params) => (
    axiosInstance(`${resource}/${params.id}`, {
      method: "DELETE",
    })
      .then(({ data }) => ({
        data: { ...data, id: data._id },
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      })
  ),

  deleteMany: (resource, params) => {
    const query = {
      filter: { ids: params.ids },
    };
    return axiosInstance(`${resource}?${stringify(query)}`, {
      method: "DELETE",
    })
      .then(({ data }) => ({ data }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },
};
