import { Edit } from "react-admin";
import { DealsFormInputs } from "./DealsFormInputs";

export const DealsEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <DealsFormInputs isEdit />
    </Edit>
  );
};
