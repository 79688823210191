import { Tooltip } from "@mui/material";
import { FunctionComponent, useMemo } from "react";
import { DeleteButton, Edit, SaveButton, SimpleForm, Toolbar, ToolbarClasses, useRecordContext } from "react-admin";
import { DEFAULT_TAG_FIELDS } from "../../constants";
import { TagTypeFormInputs } from "./TagTypeFormInputs";

const CustomToolbar: FunctionComponent<Record<string, unknown>> = (props) => {
  const record = useRecordContext();
  const isDeleteDisabled = useMemo(() => (DEFAULT_TAG_FIELDS.includes(record.name)), [record]);

  return (
    <Toolbar {...props}>
      <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
        <DeleteButton
          disabled={isDeleteDisabled}
        />
      </div>
    </Toolbar>
  );
};

export const TagTypeEdit = () => {
  return (
    <Edit>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TagTypeFormInputs />
      </SimpleForm>
    </Edit>
  );
};
