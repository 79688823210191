import { Fragment } from "react";
import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import { Box } from "@mui/material";

export const TagFormInputs = () => (
  <Fragment>
    <Box width={{ xs: "100%", sm: "50%", md: "20%" }}>
      <ReferenceInput source="tagTypeId" reference="tag-types">
        <AutocompleteInput
          label="Name"
          filterToQuery={(q) => ({ name: q })}
          fullWidth
          isRequired
        />
      </ReferenceInput>
    </Box>
    <Box width={{ xs: "100%", sm: "50%", md: "20%" }}>
      <TextInput source="value" fullWidth />
    </Box>
  </Fragment>
);
