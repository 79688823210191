import { axiosS3Instance } from "../middleware";
import { UploadFileDto } from "./types";

class AssetsService {
  async uploadFile({ url, file }: UploadFileDto): Promise<void> {
    return await axiosS3Instance.put(url, file, {
      headers: {
        "Content-type": file.type,
      },
    });
  }
}

export const assetsService = new AssetsService();
