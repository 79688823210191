import { Datagrid, EditButton, List, ShowButton, TextField } from "react-admin";

export const DealTagsList = () => {
  return (
    <List sort={{ field: "createdAt", order: "DESC" }}>
      <Datagrid>
        <TextField source="name" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
