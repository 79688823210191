import {
  ChipField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";
import { DealType } from "../../enums";
import { DealUrlField } from "./DealUrlField";

const optionalFields = [
  <TextField source="imageText" />,
  <ReferenceArrayField reference="deal-tags" sortable={false} source="tags">
    <SingleFieldList>
      <ChipField source="name" />
    </SingleFieldList>
  </ReferenceArrayField>,
];

export const DealsShowFields = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const isSlider = record.type === DealType.Slider;

  return (
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="description" />
      <ChipField source="type" />
      <FunctionField
        label="Click option"
        source="clickOption"
        render={(data, source) => {
          if (!data) {
            return null;
          }

          if (data.type === DealType.Code) {
            return <ChipField source={source} />;
          }

          return <DealUrlField source={source} />;
        }}
      />
      <ImageField source="imageUrl" label="Image" sortable={false} />
      {isSlider ? null : optionalFields}
    </SimpleShowLayout>
  );
};
