import { Edit } from "react-admin";
import { SoundExampleFormInputs } from "./SoundExampleFormInputs";

export const SoundExampleEdit = () => {
  return (
    <Edit>
      <SoundExampleFormInputs isEdit />
    </Edit>
  );
};
