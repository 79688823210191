import { Create } from "react-admin";
import { SoundExampleFormInputs } from "./SoundExampleFormInputs";

export const SoundExampleCreate = () => {
  return (
    <Create>
      <SoundExampleFormInputs isCreate />
    </Create>
  );
};
