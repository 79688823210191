import { Datagrid, EditButton, List, ReferenceInput, SelectInput, TextField } from "react-admin";

const filters = [
  <ReferenceInput source="tagTypeId" reference="tag-types">
    <SelectInput />
  </ReferenceInput>,
];

export const TagsList = () => {
  return (
    <List
      filters={filters}
      perPage={25}
    >
      <Datagrid>
        <TextField source="tagTypeId.name" label="Name" />
        <TextField source="value" />
        <TextField label="Number of tracks" source="numTracks"/>
        <EditButton />
      </Datagrid>
    </List>
  );
};
