import {
  Album as AlbumIcon,
  Audiotrack as AudiotrackIcon,
  Bookmark as BookmarkIcon,
  Gavel as TermsIcon,
  GraphicEq as SoundExamplesIcon,
  Groups as GroupsIcon,
  LocalOffer as DealsIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { BrowserRouter, Route } from "react-router-dom";

import { CustomLayout } from "./components/CustomLayout";
import { authProviderWithRefresh, dataProviderWithRefresh } from "./middleware";
import {
  AlbumCreate,
  AlbumEdit,
  AlbumShow,
  AlbumsList,
  ComposersCreate,
  ComposersEdit,
  ComposersList,
  BulkUpload,
  TagCreate,
  TagEdit,
  TagsList,
  TagTypeCreate,
  TagTypeEdit,
  TagTypesList,
  TermsList,
  TrackCreate,
  TrackEdit,
  TrackList,
  TrackShow,
  UsersCreate,
  UsersEdit,
  UsersList,
} from "./pages";
import { DealTagsCreate, DealTagsEdit, DealTagsList, DealTagsShow } from "./pages/deal-tags";
import { DealCreate, DealsEdit, DealsList, DealsShow } from "./pages/deals";
import { SoundExampleCreate, SoundExampleEdit, SoundExampleShow, SoundExamplesList } from "./pages/sound-examples";
import { TermsCreate } from "./pages/terms/TermsCreate";
import { TermsEdit } from "./pages/terms/TermsEdit";
import { TermsShow } from "./pages/terms/TermsShow";
import { Routes } from "./utils/constants";

export const App = () => (
  <BrowserRouter>
    <Admin dataProvider={dataProviderWithRefresh} authProvider={authProviderWithRefresh} layout={CustomLayout}>
      <Resource
        name="albums"
        list={AlbumsList}
        create={AlbumCreate}
        show={AlbumShow}
        edit={AlbumEdit}
        icon={AlbumIcon}
        recordRepresentation="name"
      />
      <Resource
        name="tracks"
        list={TrackList}
        create={TrackCreate}
        show={TrackShow}
        edit={TrackEdit}
        icon={AudiotrackIcon}
        recordRepresentation="name"
      />
      <Resource
        name="tags"
        list={TagsList}
        create={TagCreate}
        edit={TagEdit}
        icon={BookmarkIcon}
        recordRepresentation="tagTypeId.name"
      />
      <Resource
        name="tag-types"
        options={{ label: "Tag types" }}
        list={TagTypesList}
        create={TagTypeCreate}
        edit={TagTypeEdit}
        icon={BookmarkIcon}
        recordRepresentation="name"
      />
      <Resource
        name="composers"
        list={ComposersList}
        create={ComposersCreate}
        edit={ComposersEdit}
        icon={GroupsIcon}
        recordRepresentation="name"
      />
      <Resource
        name="users"
        list={UsersList}
        create={UsersCreate}
        edit={UsersEdit}
        icon={PeopleIcon}
        recordRepresentation="name"
      />
      <Resource
        name="terms"
        list={TermsList}
        create={TermsCreate}
        show={TermsShow}
        edit={TermsEdit}
        icon={TermsIcon}
        recordRepresentation="type"
      />
      <Resource
        name="sound-examples"
        options={{ label: "Sound examples" }}
        list={SoundExamplesList}
        show={SoundExampleShow}
        create={SoundExampleCreate}
        edit={SoundExampleEdit}
        icon={SoundExamplesIcon}
        recordRepresentation="page"
      />
      <Resource
        name="deal-tags"
        options={{ label: "Deal Tags" }}
        list={DealTagsList}
        show={DealTagsShow}
        create={DealTagsCreate}
        edit={DealTagsEdit}
        icon={BookmarkIcon}
        recordRepresentation="title"
      />
      <Resource
        name="deals"
        options={{ label: "Deals" }}
        show={DealsShow}
        list={DealsList}
        edit={DealsEdit}
        create={DealCreate}
        icon={DealsIcon}
      />
      <CustomRoutes>
        <Route path={Routes.BULK_UPLOAD} element={<BulkUpload />} />
      </CustomRoutes>
    </Admin>
  </BrowserRouter>
);
export default App;
