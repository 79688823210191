import { Edit, SimpleForm } from "react-admin";
import { TagFormInputs } from "./TagFormInputs";

export const TagEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TagFormInputs />
      </SimpleForm>
    </Edit>
  );
};
