import { CACHE_TTL_USER, LOCAL_STORAGE_KEY_ACCESS_TOKEN, LOCAL_STORAGE_KEY_REFRESH_TOKEN } from "../../constants";
import { User } from "@module/types";
import { axiosInstance } from "../../middleware";
import { LocalStorageService } from "../local-storage";

interface AuthSession {
  userId?: string;

  accessToken: string;
  accessTokenExpiresAt: number;
  accessTokenTtl: number;

  refreshToken: string;
  refreshTokenExpiresAt: number;
  refreshTokenTtl: number;
}

export class SessionService {
  static session: AuthSession;

  static sessionExpiresAt: number;

  static user: User;

  static userExpiresAt: number;

  static set(session: AuthSession): void {
    LocalStorageService.setWithExpiry(
      LOCAL_STORAGE_KEY_ACCESS_TOKEN,
      session.accessToken,
      session.accessTokenExpiresAt,
      session.accessTokenTtl,
    );

    if (session.refreshToken) {
      LocalStorageService.setWithExpiry(
        LOCAL_STORAGE_KEY_REFRESH_TOKEN,
        session.refreshToken,
        session.refreshTokenExpiresAt,
        session.refreshTokenTtl,
      );
    }
  }

  static get(): AuthSession {
    if (this.session && this.sessionExpiresAt > Date.now()) {
      return this.session;
    }

    const {
      value: accessToken,
      expiresAt: accessTokenExpiresAt,
      ttl: accessTokenTtl,
    } = LocalStorageService.getWithExpiry<string>(LOCAL_STORAGE_KEY_ACCESS_TOKEN);

    const {
      value: refreshToken,
      expiresAt: refreshTokenExpiresAt,
      ttl: refreshTokenTtl,
    } = LocalStorageService.getWithExpiry<string>(LOCAL_STORAGE_KEY_REFRESH_TOKEN);

    this.session = {
      accessToken,
      accessTokenTtl,
      accessTokenExpiresAt,
      refreshToken,
      refreshTokenExpiresAt,
      refreshTokenTtl,
    };
    this.sessionExpiresAt = accessTokenExpiresAt;

    return this.session;
  }

  static clear(): void {
    LocalStorageService.remove(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    LocalStorageService.remove(LOCAL_STORAGE_KEY_REFRESH_TOKEN);
    this.session = null;
  }

  static removeAccessToken(): void {
    LocalStorageService.remove(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    this.session.accessToken = null;
    this.session.accessTokenExpiresAt = null;
    this.session.accessTokenTtl = null;
  }

  static async refresh(): Promise<void> {
    const session = SessionService.get();
    if (!session.refreshToken) {
      SessionService.clear();
      return Promise.reject();
    }

    if (session.accessToken && session.accessTokenExpiresAt > Date.now()) {
      return Promise.resolve();
    }

    const tokens = await axiosInstance.post<AuthSession>("auth/refresh-session", undefined, {
      headers: {
        "Refresh-Token": `Bearer ${session.refreshToken}`,
      },
    });
    SessionService.set(tokens.data);
  }

  static async getMe(): Promise<User> {
    if (this.user && this.userExpiresAt > Date.now()) {
      return this.user;
    }

    const userResponse = await axiosInstance.get<User>("users/my");

    this.user = userResponse.data;
    this.userExpiresAt = Date.now() + CACHE_TTL_USER * 1000;

    return this.user;
  }
}
