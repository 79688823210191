import {
  Datagrid,
  List,
  ChipField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  DateField,
  ShowButton,
  EditButton,
  FunctionField,
  SelectArrayInput,
} from "react-admin";
import { DEAL_TYPE_SELECT_OPTIONS } from "../../constants/slider-deals.constants";
import { DealType } from "../../enums";
import { DealUrlField } from "./DealUrlField";

const filters = [<SelectArrayInput source="types" choices={DEAL_TYPE_SELECT_OPTIONS} optionValue="name" />];

export const DealsList = () => {
  return (
    <List filters={filters}>
      <Datagrid>
        <TextField source="title" />
        <ChipField source="type" />
        <ReferenceArrayField reference="deal-tags" sortable={false} source="tags">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="Click option"
          source="clickOption"
          render={(record, source) => {
            if (!record) {
              return null;
            }

            if (record.type === DealType.Code) {
              return <ChipField source={source} />;
            }

            return <DealUrlField source={source} />;
          }}
        />
        <DateField source="createdAt" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
