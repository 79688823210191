import { Datagrid, EditButton, List, TextField } from "react-admin";

export const TagTypesList = () => {
  return (
    <List perPage={25}>
      <Datagrid>
        <TextField source="name" />
        <TextField label="Number of tags" source="numTags"/>
        <EditButton />
      </Datagrid>
    </List>
  );
};
