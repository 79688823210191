import { Create, SimpleForm } from "react-admin";
import { TagTypeFormInputs } from "./TagTypeFormInputs";

export const TagTypeCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TagTypeFormInputs />
      </SimpleForm>
    </Create>
  );
};
