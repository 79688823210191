import { FunctionComponent } from "react";
import { required, SimpleForm, TextInput, useCreateContext, useEditContext } from "react-admin";

interface DealTagsFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

export const DealTagsFormInputs: FunctionComponent<DealTagsFormInputsProps> = (props: DealTagsFormInputsProps) => {
  const { isCreate, isEdit } = props;
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();

  const onSubmit = (data) => {
    if (isEdit) {
      saveOnEdit(data);
      return;
    }

    if (isCreate) {
      saveOnCreate(data);
      return;
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput validate={required()} source="name" />
    </SimpleForm>
  );
};
