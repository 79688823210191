import { Edit } from "react-admin";
import { DealTagsFormInputs } from "./DealTagsFormInputs";

export const DealTagsEdit = () => {
  return (
    <Edit>
      <DealTagsFormInputs isEdit />
    </Edit>
  );
};
