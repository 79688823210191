import { axiosInstance } from "../middleware";

class Downloads {
  constructor(private readonly apiUrl = "/downloads") {}

  reset(id: string) {
    return axiosInstance.patch(this.apiUrl + `/reset/${id}`);
  }
}

export const downloadsService = new Downloads();
