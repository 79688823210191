import isUrl from "is-url";
import { required, TextInput, Validator } from "react-admin";
import { DealType } from "../../enums";
import { useWatch } from "react-hook-form";

const validateUrl = (value) => {
  if (isUrl(value)) {
    return undefined;
  }

  return "Url is not valid. Example: https://google.com";
};

export const ClickOptionInput = () => {
  const type = useWatch({ name: "type" });

  let label = "Url/Code";
  let validationOptions: Validator[] = [required()];

  if (type !== DealType.Code) {
    label = "Url";
    validationOptions = [...validationOptions, validateUrl];
  } else {
    label = "Code";
  }

  return <TextInput validate={validationOptions} source="clickOption" label={label} />;
};
