import { Create, SimpleForm } from "react-admin";
import { ComposersFormInputs } from "./ComposersFormInputs";

export const ComposersCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <ComposersFormInputs />
      </SimpleForm>
    </Create>
  );
};
