import { Create, SimpleForm } from "react-admin";
import TermsFormInputs from "./TermsFormInputs";

export const TermsCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TermsFormInputs />
      </SimpleForm>
    </Create>
  );
};
