import { RichTextField, Show, SimpleShowLayout, TextField } from "react-admin";

export const TermsShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="type" />
        <RichTextField source="text" />
      </SimpleShowLayout>
    </Show>
  );
};
