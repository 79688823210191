import { Download as DownloadIcon, Upload as UploadIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import { AppBar, TitlePortal } from "react-admin";
import { useNavigate } from "react-router-dom";
import { bulkUploadService } from "../services";
import { Routes } from "../utils/constants";
import { BulkButton } from "./BulkButton";

export const CustomAppBar = () => {
  const navigate = useNavigate();
  return (
    <AppBar>
      <TitlePortal />
      <Stack direction="row" spacing="8px">
        <BulkButton startIcon={<UploadIcon />} onClick={() => navigate(Routes.BULK_UPLOAD)}>
          Upload file
        </BulkButton>
        <BulkButton startIcon={<DownloadIcon />} onClick={() => bulkUploadService.downloadTemplate()}>
          Download file
        </BulkButton>
      </Stack>
    </AppBar>
  );
};
