import { Show, SimpleShowLayout, TextField, SingleFieldList, ChipField, ArrayField } from "react-admin";

export const SoundExampleShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="page" />
        <ArrayField source="tracks">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
