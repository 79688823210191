import { RichTextInput } from "ra-input-rich-text";
import { Fragment } from "react";
import { required, SelectInput, TextInput } from "react-admin";

export const enum TermsType {
  HEADER = "HEADER",
  POPUP = "POPUP",
  GLOBAL = "GLOBAL",
  GDPR = "GDPR",
  COOKIES = "COOKIES",
}

const choices = [
  { id: TermsType.HEADER, name: "Header" },
  {
    id: TermsType.POPUP,
    name: "Popup",
  },
  { id: TermsType.GLOBAL, name: "Global" },
  { id: TermsType.GDPR, name: "GDPR" },
  { id: TermsType.COOKIES, name: "Cookies" },
];

const TermsFormInputs = () => {
  return (
    <Fragment>
      <SelectInput source="type" choices={choices} isRequired validate={required()} />
      <RichTextInput source="text" />
    </Fragment>
  );
};

export default TermsFormInputs;
