import { Fragment } from "react";
import { TextInput } from "react-admin";

export const ComposersFormInputs = () => (
  <Fragment>
    <TextInput
      source="name"
    />
  </Fragment>
);
