import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

type TFileButton = ButtonProps;

export const BulkButton: FC<TFileButton> = ({ children, ...restProps }) => {
  return (
    <Button {...restProps} variant="text" color="inherit">
      {children}
    </Button>
  );
};
