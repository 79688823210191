import { bulkUploadService } from "../../services";
import { assetsService } from "../../services/assets.service";
import { TDrafts, TValidationError } from "../../services/types";

type TUploadFiles = { files?: File[]; isTracks: boolean };

const getUrl = async (file: File, isTracks: boolean) => {
  if (isTracks) {
    const { data } = await bulkUploadService.getTrackUrl(file.name);
    return data.url;
  }
  const { data } = await bulkUploadService.getCoverUrl(file.name);
  return data.url;
};

const uploadFile = async (file: File, url: string) => {
  await assetsService.uploadFile({
    url,
    file,
  });
};

export const uploadFiles = async ({ isTracks, files }: TUploadFiles) => {
  if (!files) throw new Error("Files is missing");

  const uploadPromises = files.map(async (file) => {
    try {
      const url = await getUrl(file, isTracks);
      return await uploadFile(file, url);
    } catch (error) {
      return await Promise.reject(error);
    }
  });

  await Promise.allSettled(uploadPromises);
};

export const isDraftsWithValidationErrors = (response: TDrafts): response is TValidationError[] => {
  const firstElement = response[0];

  return "constraints" in firstElement && "property" in firstElement;
};
