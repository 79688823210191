import { AutocompleteArrayInput, ReferenceArrayInput, required, TextInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { REFERENCE_ARRAY_INPUT_SX } from "../../constants";
import { DealType } from "../../enums";

export const OptionalInputs = () => {
  const type = useWatch({ name: "type" });

  if (!type || type === DealType.Slider) {
    return null;
  }

  return (
    <>
      <TextInput multiline sx={REFERENCE_ARRAY_INPUT_SX} validate={required()} source="imageText" />
      <ReferenceArrayInput sort={{ field: "createdAt", order: "DESC" }} source="tags" reference="deal-tags">
        <AutocompleteArrayInput
          sx={REFERENCE_ARRAY_INPUT_SX}
          filterToQuery={(filter) => ({
            name: filter,
          })}
          optionText="name"
        />
      </ReferenceArrayInput>
    </>
  );
};
