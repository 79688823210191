import { TextField, List, Datagrid, ShowButton, EditButton } from "react-admin";

export const SoundExamplesList = () => {
  return (
    <List>
      <Datagrid>
        <TextField source="page" />
        <TextField label="Number of tracks" source="numTracks" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
